import Vue from 'vue'
import { Table, TableColumn, RadioGroup, RadioButton, Button, Select, Option, Input, Popover, DropdownMenu, Dropdown, DropdownItem, MenuItem } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Popover)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Button)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(MenuItem)
Vue.use(DropdownItem)
