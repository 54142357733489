<template>
    <div class="flex w-full h-full">
        <div
                @click="recaptcha.visible = false"
                class="p-3 flex-grow">
            <div class="flex w-full -mr-2">
                <div class="p-2 flex-grow">
                    <span class="w-full text-xs text-gray-500 uppercase block mb-1">Keyword / question</span>
                    <el-input
                            placeholder="Enter a keyword i.e. london seo"
                            :disabled="fetching"
                            v-model="term" />
                </div>
                <div class="p-2">
                    <span class="w-full text-xs text-gray-500 uppercase block mb-1">Country</span>
                    <el-select
                            :disabled="fetching"
                            v-model="country">
                        <el-option

                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="(item) of getCountries">
                            {{ item.label }}
                        </el-option>
                    </el-select>
                </div>
                <div class="p-2">
                    <span class="w-full text-xs text-gray-500 uppercase block mb-1">Language</span>
                    <el-select
                            :disabled="fetching"
                            v-model="lang">
                        <el-option

                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="(item) of getLanguages">
                            {{ item.label }}
                        </el-option>
                    </el-select>
                </div>
            </div>


        </div>
        <div class="h-full">

            <el-popover
                    ref="recaptcha-verification-popover"
                    placement="top"
                    width="330"
                    v-model="recaptcha.visible"
                    trigger="manual">
                <vue-recaptcha
                        @verify="onVerifyRecaptcha"
                        sitekey="6LeK5WUaAAAAAD_FQ16DZWG19IsRL4-nKqv_-shz">
                </vue-recaptcha>

                <el-button
                        :disabled="fetching || !termHasValue"
                        slot="reference"
                        @click="recaptcha.visible = !recaptcha.visible"
                        :loading="fetching"
                        class="h-full rounded-none el-button--search" icon="el-icon-search">Search</el-button>
            </el-popover>


        </div>
    </div>
</template>

<script>
    import Countries from "./../Data/countries";
    import Langs from "./../Data/langs";
    import VueRecaptcha from "vue-recaptcha";

    export default {
        name: "SearchComponent",
        props: {
            fetching: {
                type: Boolean,
                default: false
            },
            value: {
                type: String,
                default(){
                    return '';
                }
            },
            hl: {
                type: String,
                default(){
                    return 'gb';
                }
            },
            gl: {
                type: String,
                default(){
                    return 'en';
                }
            }
        },
        components: {
            VueRecaptcha
        },
        watch: {
            hl(){
                this.country = this.hl;
            },
            gl(){
                this.lang = this.gl;
            },
            value(){
                this.term = this.value;
            },
        },
        computed: {
                termHasValue(){
                    return this.term && this.term && this.term.replace(/\s/g,'').length > 0;
                },
                getCountries(){
                    let items = [];
                    for(let k in Countries){
                        items.push({
                            label: Countries[k],
                            value: k
                        })
                    }
                    return items;
                },
                getLanguages(){
                    let items = [];
                    for(let k in Langs){
                        items.push({
                            label: Langs[k],
                            value: k
                        })
                    }
                    return items;
                },
        },
        methods: {
            onVerifyRecaptcha(response){

                this.recaptcha.visible = false;

                this.$emit('search', {
                    q: this.term,
                    hl: this.country,
                    gl: this.lang,
                    recaptcha: response
                })

            }
        },
        data(){
            return {
                recaptcha: {
                    visible: false,
                },
                term: this.value,
                    country: this.hl,
                    lang: this.gl
            }
        }
    }
</script>

<style scoped>

</style>