<template>
  <div class="home bg-center bg-no-repeat bg-cover flex flex-col items-center justify-center">

    <div class="absolute top-0 z-30 w-full">
      <site-navigation
              link-class="px-3 uppercase text-sm text-white"
      />
    </div>

    <div class="w-full z-30 p-4 text-center text-white max-w-4xl">
      <h1 class="text-5xl leading-tight flex w-full flex-col">

        <span class="text-6xl font-bold">Explore</span>

        <span class="w-full font-semibold">
            connected

        <span class="text-spark-blue">keywords</span>
        </span>
      </h1>

      <p class="mt-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id arcu sem. Nullam bibendum placerat velit non convallis. Proin lectus odio, iaculis vitae augue a, vestibulum aliquet eros.</p>

    </div>
    <div class="film w-full absolute h-full bg-opacity-50">
    </div>

    <div class="absolute h-24 w-full bottom-0 bg-gray-200 shadow shadow-outline">
      <search-component
              @search="search"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import SiteNavigation from "../components/SiteNavigation";
import SearchComponent from "../components/SearchComponent";
export default {
  name: 'Home',
  components: {SearchComponent, SiteNavigation},
  methods: {
    search(opts){
      console.log(opts);

      window.location = '/spark/app?q=' + opts.q + '&gl=' + opts.gl + '&hl=' + opts.hl + '&recaptcha=' + opts.recaptcha

    }
  }
}
</script>
