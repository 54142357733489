import Vue from 'vue'
import App from './App.vue'
import VueCytoscape from 'vue-cytoscape'
import VueRecaptcha from 'vue-recaptcha';

import './assets/tailwind.scss'
import './plugins/element.js'
import router from './router'

Vue.config.productionTip = false

Vue.use(VueCytoscape)
Vue.component(VueRecaptcha)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
